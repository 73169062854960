import { axiosConfig } from '../services/axiosConfig'

const postCheckUsername = async ({ username, captcha }) => await axiosConfig.post('/session/check-username', { username, captcha })
const postUsernamePassword = async ({ username, password, captcha }) => axiosConfig.post('/session/username-password', { username, password, captcha })
const postAuthSession = async (params) => await axiosConfig.post('/auth/session', params)
const postAuthorizeFederated = async (params) => await axiosConfig.post('/session/federated/authorize', params)
const getSessionInfo = async () => await axiosConfig.get('/session/info')
const postFederatedInitialize = async ({ provider, callbackUrl, country, locale }) => await axiosConfig.post('/session/federated/initialize', { provider, callbackUrl, country, locale })
const getCheckVerified = async () => await axiosConfig.get('/session/email/check-verified')
const postMfaSendCode = async ({ captcha }, type) => await axiosConfig.post(`/session/mfa/${type}/send-code`, { captcha })
const postMfaVerifyCode = async ({ code, captcha }, type) => await axiosConfig.post(`/session/mfa/${type}/verify-code`, { code, captcha })
const postVerifyCodeMfa = async ({ code, captcha }) => await axiosConfig.post('/session/mfa/verify-code', { code, captcha })
const postVerifyEmailLink = async ({ otp, captcha }) => await axiosConfig.post('/email/verify-link', { otp, captcha })
const getCaptchaEngines = async () => await axiosConfig.get('/info')
const getTermsAndConditions = async (params) => await axiosConfig.get('/session/tncs', { params })
const postTermsAndConditions = async (params) => await axiosConfig.post('/session/tncs', params)
const getSignUpTncs = async (params) => await axiosConfig.get('/session/sign-up/tncs', { params })
const postRecoveryPasswordMobile = async ({ phoneNumber, captcha }) => await axiosConfig.post('/recovery/phone/password', { phoneNumber, captcha })
const postRecoveryUsername = async ({ email, captcha }) => await axiosConfig.post('/recovery/username', { email, captcha })
const postRecoveryPassword = async ({ username, captcha }) => await axiosConfig.post('/recovery/password', { username, captcha })
const postSetPrimaryEmail = async ({ email, captcha }) => await axiosConfig.post('/session/email/set-primary', { email, captcha })
const postResetPasswordMobile = async ({ phoneNumber, code, newPassword, captcha }) => await axiosConfig.post('/recovery/phone/password/reset', { phoneNumber, code, newPassword, captcha })
const postResetPasswordEmail = async ({ username, code, newPassword, captcha }) => await axiosConfig.post('/recovery/password/reset', { username, code, newPassword, captcha })
const postChangePrimaryEmail = async ({ email, sendCode, captcha }) => await axiosConfig.post('/session/email/change-primary', { email, sendCode, captcha })
const postChangePrimaryMobile = async ({ phoneNumber, sendCode, captcha }) => await axiosConfig.post('/session/phone/change-primary', { phoneNumber, sendCode, captcha })
const postVerifyCodeMobile = async ({ code, captcha }) => await axiosConfig.post('/session/phone/verify-code', { code, captcha })
const postVerifyCodeEmail = async ({ code, captcha }) => await axiosConfig.post('/session/email/verify-code', { code, captcha })
const postEmailOrPhoneSendCode = async (payload, type = 'email') => await axiosConfig.post(`/session/${type}/send-code`, payload)
const postBackToApp = async () => await axiosConfig.post('/session/back-to-app')
const getSmsSupportedCountryNumbers = async () => await axiosConfig.get('/session/sms/supported-countries')
const postSessionSignUp = async (payload) => await axiosConfig.post('/session/sign-up', payload)
const postFederatedSignup = async (payload) => await axiosConfig.post('/session/federated/sign-up', payload)
const postScopeConsents = async (payload) => await axiosConfig.post('/session/scope-consents', payload)
const getScopeConsents = async () => await axiosConfig.get('/session/scope-consents')
const getHealth = async () => await axiosConfig.get('/health')
const postSessionChangePassword = async ({ password, newPassword, captcha }) => await axiosConfig.post('/session/change-password', { password, newPassword, captcha })
const postAddInfo = async (payload) => axiosConfig.post('/session/sign-up/add-info', payload)
const postFederatedAddInfo = async (payload) => axiosConfig.post('/session/federated/sign-up/add-info', payload)
const postFinalize = async () => axiosConfig.post('/session/sign-up/finalize')
const postFederatedFinalize = async () => axiosConfig.post('/session/federated/sign-up/finalize')
const postFederatedChangePrimaryMobile = async ({ phoneNumber, sendCode, captcha }) => await axiosConfig.post('/session/federated/phone/change-primary', { phoneNumber, sendCode, captcha })
const postFederatedChangePrimaryEmail = async ({ email, sendCode, captcha }) => await axiosConfig.post('/session/federated/email/change-primary', { email, sendCode, captcha })
const postUIEvents = async ({ eventName }) => await axiosConfig.post(`/ui-event/${eventName}`)
const postPrepareArkoseSession = async (payload) => await axiosConfig.post('/session/prepare-arkose-session', payload)
const postOTPLoginSendCode = async (payload, type = 'email') => await axiosConfig.post(`/session/otp-login/${type}/send-code`, payload)
const postOTPLoginPVerifyCode = async (payload, type = 'email') => await axiosConfig.post(`/session/otp-login/${type}/verify-code`, payload)

const bff = {
  getSmsSupportedCountryNumbers,
  postTermsAndConditions,
  getScopeConsents,
  postScopeConsents,
  postEmailOrPhoneSendCode,
  postFederatedInitialize,
  postResetPasswordMobile,
  postResetPasswordEmail,
  postRecoveryPasswordMobile,
  postRecoveryUsername,
  postRecoveryPassword,
  postCheckUsername,
  postUsernamePassword,
  postAuthSession,
  postAuthorizeFederated,
  postMfaSendCode,
  postMfaVerifyCode,
  postVerifyCodeMfa,
  postVerifyEmailLink,
  getCaptchaEngines,
  getSessionInfo,
  getTermsAndConditions,
  getSignUpTncs,
  getCheckVerified,
  postSetPrimaryEmail,
  postChangePrimaryEmail,
  postChangePrimaryMobile,
  postVerifyCodeMobile,
  postVerifyCodeEmail,
  postBackToApp,
  postSessionSignUp,
  postFederatedSignup,
  getHealth,
  postSessionChangePassword,
  postAddInfo,
  postFederatedAddInfo,
  postFinalize,
  postFederatedFinalize,
  postFederatedChangePrimaryMobile,
  postFederatedChangePrimaryEmail,
  postUIEvents,
  postPrepareArkoseSession,
  postOTPLoginPVerifyCode,
  postOTPLoginSendCode
}

export default bff
