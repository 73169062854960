import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import InlineNotification from '@veneer/core/dist/esm/scripts/inline_notification'
import Button from '@veneer/core/dist/esm/scripts/button'
import { State } from '../../common/types'
import udlEvents from '../../common/udlEvents'
import { OTP_STATUS, OTP_BUTTON_LABEL } from '../../constants.js'

import * as S from './styles'
import { useEffect } from 'react'

interface OTPSignInProps {
  isPasswordLoading: boolean
  isOTPLoading: boolean
  handleOTP?: () => void
  username?: string
}

const OTPSignInComponent = ({ isPasswordLoading, isOTPLoading, handleOTP, username }: OTPSignInProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { user, session, loading } = useSelector((state: State) => state)
  const { retryDelay, otpLoginButton } = session

  const handleOnClick = () => {
    if (otpLoginButton === OTP_BUTTON_LABEL.USE_ONE_TIME_PASSCODE ) {
      dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT194'))
    }
    if (otpLoginButton === OTP_BUTTON_LABEL.SEND_SIGN_IN_CODE ) {
      dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT199'))
    }
    handleOTP()
  }

  useEffect(() => {
    if (OTP_STATUS.includes(user.lastOTPStatus) && retryDelay) {
      dispatch.session.update({ retryDelay: false })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [username])

  const udlUsePasswordButtonClick = () => {
    dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT202'))
  }

  return (
    <>
      <S.otpButtonsContainer>
        <Button
          id="user-name-form-submit"
          name="use-password"
          type="submit"
          loading={isPasswordLoading}
          disabled={isOTPLoading || loading.effects.user.otpLoginSendCode}
          expanded
          onClick={() => udlUsePasswordButtonClick()}
        >
          {t('button.use_password')}
        </Button>
      </S.otpButtonsContainer>
      <S.SeparatorOrString>
        <p>{t('label.or')}</p>
      </S.SeparatorOrString>
      <S.otpButtonsContainer>
        <Button
          id="use-otp-button"
          name="use-otp-button"
          loading={isOTPLoading || loading.effects.user.otpLoginSendCode}
          disabled={retryDelay || isPasswordLoading}
          onClick={handleOnClick}
          appearance="secondary"
          expanded
        >
          {t(otpLoginButton || OTP_BUTTON_LABEL.USE_ONE_TIME_PASSCODE)}
        </Button>
      </S.otpButtonsContainer>
      {retryDelay && (
        <S.PasscodeResentContainer>
          <InlineNotification
            id="wait-for-request-passcode-desc"
            aria-label="wait-for-request-passcode-desc"
            hideIcon={false}
            closeButton={false}
            title={t('label.useotp_wait')}
            type="informative"
          />
        </S.PasscodeResentContainer>
      )}
    </>
  )
}

export default OTPSignInComponent
