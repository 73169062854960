import { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation, Trans } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import Button from '@veneer/core/dist/esm/scripts/button'
import {
  DefaultHeader,
  MainLayout,
  UICheckbox,
  UILink,
  UIPassword,
  UITextBox,
  TnCSignUp,
  UIMobileNumber,
  CountrySelect
} from '../../component'
import {
  isValidEmail,
  isLastNameBeforeFirst,
  getPasswordRequirements,
  sleep,
  filterUsername,
  validateMobileNumber,
  validateCountry,
  isPhoneString,
  handleCheckboxOnKeyDown
} from '../../util'
import {
  ARKOSE_KEYS,
  ARKOSE_SELECTORS,
  SIMPLIFIED_CHINESE_LOCALE,
  PARAM_ALLOW_RETURN,
  PARAM_EMAIL,
  PARAM_FIRSTNAME,
  PARAM_LASTNAME,
  USERNAME_TYPES,
  IDENTITY_PROVIDER,
  PAGE_PASSWORD,
  PAGE_LEARN_MORE
} from '../../constants'
import { conditionHideConsentCheckbox } from '../../features'
import useSendEvent from '../../customHooks/useSendEvent'
import { State } from '../../common/types'
import udlEvents from '../../common/udlEvents'
import useDirection from '../../customHooks/useDirection'

import * as S from './styles'

interface LocationState {
  provider: string
}

const refs = {}

const OOBESignUp = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { user } = useSelector((state: State) => state)
  const { client, isTNCLoaded, showPhoneField, smsSupportedCountryNumbers, signUpEventSent } = useSelector(
    (state: State) => state.session
  )
  const { submitSplitSignUp } = useSelector((state: State) => state.loading.effects.user)
  const [isSimplifiedChinese, setIsSimplifiedChinese] = useState(SIMPLIFIED_CHINESE_LOCALE.includes(user.locale))
  const { provider = '' } = (location.state as LocationState) || {}
  const [textVisibility, setTextVisibility] = useState('auto')
  const [isLoading, setLoading] = useState(false)
  const { isRTL } = useDirection()

  // Returns true if the client does not provide the marketingConsent attribute, and returns false if the marketingConsent is an empty array.
  const hasMarketingConsents = client.marketingConsents === undefined ? true : client.marketingConsents.length > 0

  // Hook form definition
  const getMarketProperty = hasMarketingConsents ? user.country === 'US' || user.market || false : undefined

  // Determine if the renderMarketConsent should be rendered based on user type and `conditionHideConsentCheckbox` flag
  const renderMarketConsent =
    user.usernameType === USERNAME_TYPES.USERNAME_EMAIL && (!conditionHideConsentCheckbox || hasMarketingConsents)

  const isOneHp = provider && provider.toLowerCase() === IDENTITY_PROVIDER.ONE_HP

  const submitSelector = ARKOSE_SELECTORS.OOBE_SIGN_UP.SUBMIT_BUTTON
  const allowReturn = user[PARAM_ALLOW_RETURN]
  const firstName = filterUsername(user[PARAM_FIRSTNAME])
  const lastName = filterUsername(user[PARAM_LASTNAME])
  const email = user.username

  const fullName = () => {
    const name = [firstName, lastName]
    if (isLastNameBeforeFirst(user.locale)) name.reverse()
    return `${name[0]} ${name[1]}`
  }

  const USER = fullName()

  const defaultValues = {
    firstName: firstName,
    lastName: lastName,
    email: user[PARAM_EMAIL] || email,
    password: '',
    ...(renderMarketConsent && {
      market: getMarketProperty
    }),
    phoneNumber: isPhoneString(user.username) ? user.username : '',
    countryResidence: validateCountry(user.country) ? user.country : ''
  }

  const {
    handleSubmit,
    formState: { errors, isDirty },
    setError,
    control
  } = useForm({ mode: 'onBlur', defaultValues })

  const renderEmailInput = () => {
    const email = {
      id: 'email',
      'aria-label': 'email',
      autoComplete: 'email',
      error: Boolean(errors.email),
      label: t('label.placeholder_email'),
      helperTextVisibility: textVisibility,
      onBlur: async () => {
        setTextVisibility('visible')
        await sleep(100)
        setTextVisibility('auto')
      },
      helperText: errors.email
        ? typeof errors.email.message === 'string'
          ? t(errors.email.message)
          : setConflictError('username')
        : t('label.sign_up_email_subtitle'),
      refs,
      required: true,
      control,
      rules: {
        required: 'form.err_email_empty',
        validate: (value) => {
          const checkResult = isValidEmail(value, !isOneHp)
          if (!value.trim()) return 'form.err_email_empty'
          if (checkResult === true) return
          if (!checkResult.result && checkResult.isEmail && checkResult.isHpEmail) {
            dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT146'))
            return 'form.err_email_hp_invalid'
          }
          if (!checkResult.result && !checkResult.isEmail) {
            return 'form.err_email_invalid'
          }
        }
      }
    }
    return <UITextBox key={0} {...email} />
  }

  const renderPhoneNumberInput = () => {
    const mobile = {
      id: 'phoneNumber',
      'aria-label': 'phoneNumber',
      error:
        errors.phoneNumber &&
        (typeof errors.phoneNumber.message === 'string'
          ? t(errors.phoneNumber.message)
          : setConflictError('mobile')),
      control,
      rules: {
        validate: (value) => validateMobileNumber(value)
      },
      label: t('label.mobile_optional')
    }

    return <UIMobileNumber key={1} {...mobile} />
  }

  const setConflictError = (context) => (
    <Trans i18nKey="link.already_have_account">
      Already have an HP account?
      <UILink
        onClick={() => {
          dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT152'))
          navigate(PAGE_PASSWORD)
        }}
        id={context === 'username' ? 'already-exists' : 'already-exists-mobile'}
        ariaLabel="already-have-account"
        error
      >
        Sign in
      </UILink>
    </Trans>
  )

  const setWeakPasswordError = () => (
    <Trans i18nKey="label.update_app_for">
      For security reasons this password cannot be used.
      <UILink
        onClick={() => {
          dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT179'))
          navigate(PAGE_LEARN_MORE)
        }}
        ariaLabel="learn-more-link"
        id="learn-more-link"
        error
      >
        Learn more
      </UILink>
    </Trans>
  )

  const onSubmit = async (data) => {
    const updatedData = {
      ...data
    }

    if (updatedData.hasOwnProperty('email')) {
      updatedData.email = updatedData.email.trim()
    }

    setLoading(true)

    const options = {
      data: updatedData,
      setError,
      setLoading,
      setConflictError,
      setWeakPasswordError,
      isOobeSignUp: true,
    }

    const arkoseParams = {
      options,
      submitSelector,
      callback: dispatch.user.splitSignUpFormSubmit,
      onHide: () => setLoading(false),
      formErrors: errors,
      arkoseKey: ARKOSE_KEYS.SIGN_UP
    }

    dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT144'))

    await dispatch.user.splitSignUpFormSubmit({ options: { ...options, arkoseParams } })
  }

  const handleFormSubmit = (e) => {
    e.preventDefault()
    dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT143'))
    handleSubmit(onSubmit)()
  }

  const triggerUdlConsentEvent = (e) => {
    e.target.checked
      ? dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT150'))
      : dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT151'))
  }

  useEffect(() => {
    if (!smsSupportedCountryNumbers.length) dispatch.session.fetchSmsSupportedCountryNumbers()
  }, [smsSupportedCountryNumbers, dispatch.session, user.country])

  useEffect(() => {
    setIsSimplifiedChinese(SIMPLIFIED_CHINESE_LOCALE.includes(user.locale))
  }, [user.locale])

  useEffect(() => {
    if (errors.password && errors.password.type === 'required') {
      dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT149'))
    }

    if (errors.password && errors.password.message === 'form.password_check_requirements') {
      dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT148'))
    }
  }, [dispatch.udl, errors.password])

  useEffect(() => {
    if (errors.email && errors.email.type === 'required') {
      dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT147'))
    }
  }, [dispatch.udl, errors.email])

  useSendEvent(isDirty, signUpEventSent)

  return (
    <MainLayout isFooterOn={false}>
      <DefaultHeader title={t('label.oobe_sign_up_title')} hasSignInLink={true} />
      <S.OOBESignUpForm onSubmit={(e) => handleFormSubmit(e)}>
        <S.FormContainer>
          <S.GreetingText aria-label="oobe-sign-up-greeting">
            <Trans
              i18nKey="label.oobe_sign_up_greetings"
              components={{ bold: <strong /> }}
              values={{ USER: `<bold>${USER}</bold>` }}
            />
          </S.GreetingText>
          <S.DescriptionText>{t('label.oobe_sign_up_desc')}</S.DescriptionText>
          {renderEmailInput()}
          {showPhoneField && renderPhoneNumberInput()}
          <UIPassword
            aria-label="password"
            id="password"
            autoComplete="off"
            label={t('label.placeholder_password')}
            error={errors.password}
            weakPasswordError={setWeakPasswordError}
            control={control}
            rules={{
              required: 'form.err_password_create_empty',
              validate: (value) => {
                if (!value.trim()) return 'form.err_password_create_empty'
                if (!getPasswordRequirements(value).isValidPassword) {
                  return 'form.password_check_requirements'
                }
              }
            }}
            isWithReqs
          />
          {showPhoneField && (
            <CountrySelect
              errors={errors}
              control={control}
              onChange={(value) => {
                dispatch.user.update({ country: value })
              }}
              key={user.locale}
            />
          )}
          {renderMarketConsent && (
            <S.CheckBoxConsentWorkaround>
              <UICheckbox
              aria-label="marketConsent"
              id="market"
              label={t('label.marketing_consent')}
              control={control}
              onClick={triggerUdlConsentEvent}
              onKeyDown={handleCheckboxOnKeyDown}
            />
            </S.CheckBoxConsentWorkaround>
          )}
        </S.FormContainer>
        <S.FormFooter isRTL={isRTL}>
          <TnCSignUp />
          <Button
            type="submit"
            id={submitSelector}
            name={submitSelector}
            loading={isLoading || submitSplitSignUp}
            disabled={!isTNCLoaded}
            expanded
          >
            {isSimplifiedChinese ? t('button.accept_create_account') : t('button_capitalize.create')}
          </Button>
          {allowReturn && (
            <div>
              <UILink
                id="back-link"
                onClick={() => {
                  dispatch.user.redirectToAPP()
                }}
              >
                {isSimplifiedChinese ? t('link.decline_create_account') : t('link.not_now')}
              </UILink>
            </div>
          )}
        </S.FormFooter>
      </S.OOBESignUpForm>
    </MainLayout>
  )
}

export default OOBESignUp
