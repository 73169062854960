const appConfig = window.__appConfig || {}
const federation = window.__federation || {}

let localFeatureFlags = {}
if (process.env.NODE_ENV === 'development') {
  localFeatureFlags = JSON.parse(process.env.REACT_APP_FEATURE_FLAG || {})
}

const config = {
  applicationPrefix: '/login3',
  // backendUrl: appConfig.backendUrl || 'http://localhost:8080/bff/v1',
  backendUrl: appConfig.backendUrl || 'https://ui-backend.dev.cd.id.hp.com/bff/v1',
  directoryUrl: appConfig.directoryUrl || 'https://directory.dev.cd.id.hp.com',
  uaaUrl: appConfig.uaaUrl || 'https://myaccount.dev.cd.id.hp.com/uaa',
  geoURL: appConfig.geoURL || 'https://login3.dev.cd.id.hp.com/login3/country',
  extUrl: appConfig.extUrl || 'https://login3.dev.cd.id.hp.com/ext/google-authn',

  // S P L U N K   /   R U M
  // -----------------------
  rum: {
    token: appConfig?.rum?.token || 'csFOpbeBEOcxbo3C5XKb8Q',
    // possible TODO: move this to our own CDN
    url: appConfig?.rum?.url || 'https://cdn.signalfx.com/o11y-gdi-rum/v0.15.3/splunk-otel-web.js',
    integrity:
      appConfig?.rum?.integrity || 'sha384-Ijfcxm/9ws/10eduoytZYQxmMEfn+K6yck2icRGkInNE+z5Buln9FrJVk1y21uBx',
    beaconUrl: appConfig?.rum?.beaconUrl || 'https://rum-ingest.us1.signalfx.com/v1/rum',
    applicationName: appConfig?.rum?.applicationName || 'myCredentials login UI',
    environment: appConfig?.rum?.environment || 'dev',
    version: appConfig.rum?.version || '1.0.0'
  },

  SAMLResponse: federation.SAMLResponse || '',
  RelayState: federation.RelayState || '',
  gtmId: 'GTM-MZXB4R4',
  emailRegexBlockingHp:
    /^(?=.{6,254}$)[a-z0-9$&'+=_-]+(?:\.[a-z0-9$&'+=_-]+)*@(?!hp\.com)(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+([a-zA-Z]{2,})$/i,
  emailRegex:
    /^(?=.{6,254}$)[a-z0-9$&'+=_-]+(?:\.[a-z0-9$&'+=_-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+([a-zA-Z]{2,})$/i,
  verificationCodeRegex: /^[A-Z0-9]*$/i,
  defaultLocale: 'en_US',
  paramDefaultValues: {
    hide_create: false,
    allow_return: false,
    remember_me: true
  },
  providerSortOrder: ['onehp', 'azure', 'google', 'facebook', 'wechat', 'hpid'],
  hpidProvider: {
    name: 'hpid',
    type: 'native',
    identityProvider: 'hpid',
    multicolor: false,
    color: 'blue',
    icon: 'user'
  },
  lastNameBeforeFirst: ['ja_jp', 'ko_kr', 'zh_cn', 'zh_tw', 'zh_hk', 'zh_sg'],
  supportedLocales: [
    'ar_sa',
    'ar_ye',
    'bg_bg',
    'cs_cz',
    'da_dk',
    'de_at',
    'de_ch',
    'de_de',
    'el_gr',
    'en_ae',
    'en_au',
    'en_aw',
    'en_bb',
    'en_bd',
    'en_bg',
    'en_bs',
    'en_ca',
    'en_eg',
    'en_gb',
    'en_gd',
    'en_hk',
    'en_id',
    'en_ie',
    'en_il',
    'en_in',
    'en_jm',
    'en_kw',
    'en_lb',
    'en_lk',
    'en_ma',
    'en_my',
    'en_nz',
    'en_pk',
    'en_ph',
    'en_ro',
    'en_sa',
    'en_sg',
    'en_si',
    'en_th',
    'en_tt',
    'en_us',
    'en_vn',
    'en_za',
    'es_ar',
    'es_bo',
    'es_co',
    'es_cl',
    'es_cr',
    'es_do',
    'es_ec',
    'es_es',
    'es_gt',
    'es_hn',
    'es_lx',
    'es_mx',
    'es_ni',
    'es_sv',
    'es_pa',
    'es_pe',
    'es_pr',
    'es_py',
    'es_uy',
    'es_ve',
    'et_ee',
    'fi_fi',
    'fr_be',
    'fr_ca',
    'fr_ch',
    'fr_fr',
    'fr_lu',
    'he_il',
    'hr_hr',
    'hu_hu',
    'id_id',
    'it_ch',
    'it_it',
    'ja_jp',
    'ko_kr',
    'lt_lt',
    'lv_lv',
    'nl_be',
    'nl_nl',
    'nb_no',
    'pl_pl',
    'pt_br',
    'pt_pt',
    'ro_ro',
    'ru_ru',
    'sk_sk',
    'sl_si',
    'sr_rs',
    'sv_se',
    'th_th',
    'tr_tr',
    'zh_cn',
    'zh_hk',
    'zh_sg',
    'zh_tw'
  ],
  supportedCountries: [
    'AF',
    'AL',
    'DE',
    'AD',
    'AO',
    'AI',
    'AQ',
    'AG',
    'AR',
    'DZ',
    'AM',
    'AW',
    'SA',
    'AU',
    'AZ',
    'BS',
    'BH',
    'BD',
    'BB',
    'BZ',
    'BJ',
    'BM',
    'BO',
    'BW',
    'BR',
    'BN',
    'BG',
    'BF',
    'BI',
    'BT',
    'BE',
    'BA',
    'CV',
    'CM',
    'KH',
    'CA',
    'QA',
    'KZ',
    'TD',
    'CL',
    'CN',
    'CY',
    'VA',
    'CO',
    'KM',
    'CD',
    'KR',
    'CR',
    'CI',
    'HR',
    'CW',
    'DK',
    'DJ',
    'DM',
    'EG',
    'SV',
    'AE',
    'EC',
    'ER',
    'SK',
    'SI',
    'ES',
    'US',
    'EE',
    'ET',
    'FJ',
    'PH',
    'FI',
    'FR',
    'GA',
    'GH',
    'GE',
    'GI',
    'GD',
    'GL',
    'GR',
    'GP',
    'GU',
    'GT',
    'GG',
    'GY',
    'GF',
    'GN',
    'GQ',
    'GW',
    'GM',
    'HT',
    'HN',
    'HK',
    'HU',
    'BV',
    'CX',
    'NF',
    'IM',
    'AX',
    'KY',
    'CC',
    'CK',
    'FO',
    'FK',
    'MP',
    'MH',
    'UM',
    'PN',
    'SB',
    'TC',
    'VI',
    'VG',
    'ID',
    'IQ',
    'IE',
    'IS',
    'IL',
    'IT',
    'YE',
    'JM',
    'JP',
    'JE',
    'JO',
    'XK',
    'KW',
    'LA',
    'LS',
    'LV',
    'LR',
    'LI',
    'LT',
    'LU',
    'LB',
    'LY',
    'MO',
    'MK',
    'MG',
    'MW',
    'MV',
    'ML',
    'MT',
    'MY',
    'MA',
    'MQ',
    'MR',
    'MU',
    'YT',
    'MM',
    'FM',
    'MD',
    'MN',
    'ME',
    'MS',
    'MZ',
    'MX',
    'MC',
    'NA',
    'NR',
    'NP',
    'NI',
    'NG',
    'NU',
    'NO',
    'NC',
    'NZ',
    'NE',
    'OM',
    'PW',
    'PA',
    'PG',
    'PK',
    'PY',
    'NL',
    'BQ',
    'PE',
    'PF',
    'PL',
    'PR',
    'PT',
    'KG',
    'KI',
    'KE',
    'GB',
    'CF',
    'DO',
    'CG',
    'RE',
    'RO',
    'RW',
    'EH',
    'WS',
    'AS',
    'SM',
    'SH',
    'LC',
    'SC',
    'SN',
    'SL',
    'SG',
    'SX',
    'SO',
    'LK',
    'SZ',
    'SD',
    'SS',
    'SR',
    'SE',
    'CH',
    'SJ',
    'BL',
    'KN',
    'MF',
    'PM',
    'ST',
    'VC',
    'RS',
    'TJ',
    'TH',
    'TW',
    'TZ',
    'CZ',
    'IO',
    'TF',
    'PS',
    'TL',
    'TG',
    'TK',
    'TO',
    'TT',
    'TN',
    'TM',
    'TR',
    'TV',
    'UA',
    'UG',
    'UY',
    'UZ',
    'VU',
    'VE',
    'VN',
    'WF',
    'ZW',
    'ZM',
    'ZA',
    'AT',
    'IN'
  ],
  twoLettersLocaleConfig: {
    ar: 'ar_SA',
    bg: 'bg_BG',
    cs: 'cs_CZ',
    da: 'da_DK',
    de: 'de_DE',
    el: 'el_GR',
    en: 'en_US',
    es: 'es_ES',
    et: 'et_EE',
    fi: 'fi_FI',
    fr: 'fr_FR',
    he: 'he_IL',
    hr: 'hr_HR',
    hu: 'hu_HU',
    id: 'id_ID',
    it: 'it_IT',
    ja: 'ja_JP',
    ko: 'ko_KR',
    lt: 'lt_LT',
    lv: 'lv_LV',
    nb: 'nb_NO',
    nl: 'nl_NL',
    pl: 'pl_PL',
    pt: 'pt_PT',
    ro: 'ro_RO',
    ru: 'ru_RU',
    sk: 'sk_SK',
    sl: 'sl_SI',
    sr: 'sr_RS',
    sv: 'sv_SE',
    th: 'th_TH',
    tr: 'tr_TR',
    zh: 'zh_CN'
  },
  arkoseSupportedLanguages: [
    'ar',
    'bg',
    'cs',
    'da',
    'de',
    'el',
    'en-gb',
    'en',
    'es-ar',
    'es-bo',
    'es-cl',
    'es-co',
    'es-cr',
    'es-do',
    'es-ec',
    'es-gt',
    'es-hn',
    'es-mx',
    'es-ni',
    'es-pa',
    'es-pe',
    'es-pr',
    'es-py',
    'es-sv',
    'es',
    'et',
    'fi',
    'fr-ca',
    'fr',
    'he',
    'hr',
    'hu',
    'id',
    'it',
    'ja',
    'ko',
    'lt',
    'lv',
    'nl',
    'no',
    'pl',
    'pt-br',
    'pt',
    'ro',
    'ru',
    'sk',
    'sl',
    'sr',
    'sv',
    'th',
    'tr',
    'zh',
    'zh-TW',
    'zh-HK'
  ],
  identityProviders: [
    {
      name: 'Facebook',
      displayName: 'Facebook',
      type: 'facebook',
      identityProvider: 'facebook',
      multicolor: true,
      icon: 'facebook'
    },
    {
      name: 'GooglePlus',
      displayName: 'Google',
      type: 'oidc',
      identityProvider: 'google',
      multicolor: true,
      icon: 'google'
    },
    {
      name: 'OneHP',
      displayName: 'OneHP',
      type: 'saml',
      identityProvider: 'onehp',
      multicolor: false,
      color: 'blue',
      icon: 'hp'
    },
    {
      name: 'MSP',
      displayName: 'HP MSP',
      type: 'saml',
      identityProvider: 'msp',
      multicolor: false,
      color: 'blue',
      icon: 'msp'
    },
    {
      name: 'WeChat',
      displayName: 'WeChat',
      type: 'wechat',
      identityProvider: 'wechat',
      multicolor: true,
      icon: 'wechat'
    },
    {
      name: 'Azure',
      displayName: 'Microsoft',
      type: 'azure',
      description: 'Azure Active Directory',
      identityProvider: 'azure',
      multicolor: true,
      icon: 'microsoft',
      text: 'link.sign_in_idp'
    }
  ],
  session_timeout: [30, 'minutes'],
  delay_timer: [60, 'seconds'],
  featureFlags: appConfig.featureFlags || localFeatureFlags
}

export default config
