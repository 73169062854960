import { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import Button from '@veneer/core/dist/esm/scripts/button'
import { State } from '../../common/types'
import { DefaultHeader, MainLayout, UICheckbox, UIMobileNumber, UITextBox, CountrySelect } from '../../component'
import { PARAM_EMAIL, USERNAME_TYPES, ARKOSE_SELECTORS } from '../../constants'
import {
  isPhoneString,
  isValidEmail,
  validateCountry,
  validateMobileNumber,
  handleCheckboxOnKeyDown,
  shouldReduceMobileLabel
} from '../../util'
import { conditionHideConsentCheckbox } from '../../features'
import udlEvents from '../../common/udlEvents'

import * as S from './Styles'

const CompleteAccount = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { user, session } = useSelector((state: State) => state)
  const { client, smsSupportedCountryNumbers } = session
  const [isLoading, setLoading] = useState(false)
  const [reduceLabel, setReduceLabel] = useState(shouldReduceMobileLabel(user.locale))

  // Returns true if the client does not provide the marketingConsent attribute, and returns false if the marketingConsent is an empty array.
  const hasMarketingConsents = client.marketingConsents === undefined ? true : client.marketingConsents.length > 0
  const getMarketProperty = hasMarketingConsents ? user.country === 'US' || user.market || false : undefined
  // Disable email field when is a federated account and already have an email address.
  const isEmailDisabled = user.isExternalIdentity && user[PARAM_EMAIL]
  const userHasEmail = user.usernameType === USERNAME_TYPES.USERNAME_EMAIL

  // Determine if the renderMarketConsent should be rendered based on user type and `conditionHideConsentCheckbox` flag
  const renderMarketConsent = !userHasEmail && (!conditionHideConsentCheckbox || hasMarketingConsents)

  const defaultValues = {
    email: user[PARAM_EMAIL] || '',
    phoneNumber: isPhoneString(user.username) ? user.username : '',
    countryResidence: validateCountry(user.country) ? user.country : '',
    ...(!userHasEmail && {
      market: getMarketProperty
    })
  }

  const {
    handleSubmit,
    formState: { errors, isDirty },
    getValues,
    setError,
    setValue,
    control
  } = useForm({ mode: 'onBlur', defaultValues, shouldUnregister: true })

  const hasEmailValue = getValues()?.email || userHasEmail

  const onSubmit = async (data) => {
    data.email = data?.email?.replace(/\s/g, '');
    // Remove email from payload when is a federated account and isn't weChat.
    if (data.email && isEmailDisabled) {
      delete data.email
    }

    // Remove marketingConsent from payload when it's checked and the email is empty
    if ((data.market && !hasEmailValue) || !data?.email?.length) {
      delete data.market
    }

    const options = { setError, data, isDirty, setLoading }
    setLoading(true)

    await dispatch.user.completeAccount({ options })
  }

  const propsEmail = {
    id: 'email',
    autoComplete: 'email',
    error: !!errors.email,
    label: t('label.email_optional'),
    helperText: errors.email && t(errors.email.message as string),
    disabled: isEmailDisabled,
    required: false,
    control,
    rules: {
      validate: (value) => {
        const checkResult = isValidEmail(value, true)

        if (!value.trim()) return true
        if (typeof checkResult === 'boolean' && checkResult === true) return
        if (!checkResult.result && checkResult.isEmail && checkResult.isHpEmail) {
          dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT89'))
          return 'form.err_email_hp_invalid'
        }
        if (!checkResult.result && !checkResult.isEmail) {
          return 'form.err_email_invalid'
        }
      }
    }
  }

  const propsMobile = {
    id: 'phoneNumber',
    required: false,
    label: t('label.mobile_optional'),
    error:
      errors.phoneNumber &&
      (typeof errors.phoneNumber.message === 'string'
        ? t(errors.phoneNumber.message)
        : t('form.err_username_conflict')),
    displayDisclaimer: false,
    control,
    rules: {
      validate: (value) => validateMobileNumber(value)
    }
  }

  const triggerUdlConsentEvent = (e) => {
    e.target.checked
      ? dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT84'))
      : dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT85'))
  }

  const handleFormSubmit = (e) => {
    e.preventDefault()
    dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT86'))
    handleSubmit(onSubmit)()
  }

  const email = <UITextBox aria-label="email" {...propsEmail} />
  const mobile = <UIMobileNumber {...propsMobile} />

  useEffect(() => {
    if (!smsSupportedCountryNumbers.length) dispatch.session.fetchSmsSupportedCountryNumbers()
  }, [smsSupportedCountryNumbers, dispatch.session, user.country])

  useEffect(() => {
    setReduceLabel(shouldReduceMobileLabel(user.locale))
  }, [user.locale])

  return (
    <MainLayout>
      <S.ContentWrapper>
        <DefaultHeader title={t('label.complete_account')} />
        <p aria-label="labelText">
          {!userHasEmail ? t('label.complete_acc_desc_email') : t('label.complete_acc_desc_mobile')}
        </p>
        <form onSubmit={(e) => handleFormSubmit(e)}>
          <S.FormWrapper marginTop reduceLabel={reduceLabel}>
            {!userHasEmail ? email : mobile}
          </S.FormWrapper>
          <S.FormWrapper>
            <CountrySelect
              errors={errors}
              control={control}
              onChange={(value) => {
                if (!userHasEmail) setValue('market', value === 'US')
                dispatch.user.update({ country: value })
              }}
              key={user.locale}
            />
          </S.FormWrapper>
          {renderMarketConsent && (
            <S.CheckBoxConsentWorkaround>
              <UICheckbox
                aria-label="marketConsent"
                id="market"
                label={t('label.marketing_consent')}
                control={control}
                onClick={triggerUdlConsentEvent}
                onKeyDown={handleCheckboxOnKeyDown}
              />
            </S.CheckBoxConsentWorkaround>
          )}
          <S.ButtonWrapper>
            <Button
              id={ARKOSE_SELECTORS.COMPLETE_ACCOUNT.SUBMIT_BUTTON}
              aria-label="submitButton"
              type="submit"
              disabled={isLoading}
              expanded
            >
              {t('button_capitalize.continue')}
            </Button>
          </S.ButtonWrapper>
        </form>
      </S.ContentWrapper>
    </MainLayout>
  )
}

export default CompleteAccount
