import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Checkbox from '@veneer/core/dist/esm/scripts/checkbox'
import Button from '@veneer/core/dist/esm/scripts/button/button'
import { SUPPORT_HPID, PARAM_ALLOW_RETURN, IS_PRISTINE, PAGE_USERNAME_RECOVERY, USERNAME_TYPES } from '../../constants'
import { State } from '../../common/types'
import udlEvents from '../../common/udlEvents'
import { displayUpdateAppNotification } from '../../features'
import { isWebAuthBroker, handleCheckboxOnKeyDown } from '../../util'
import {
  MainLayout,
  SocialWrapper,
  UISpinner,
  DefaultHeader,
  UsernameForm,
  UILink,
  UpdateAppNotification
} from '../../component'
import useDirection from '../../customHooks/useDirection'

import * as S from './styles'

const Username = () => {
  const { t } = useTranslation()
  const { client, smsSupportedCountryNumbers, supportedIDP } = useSelector((state: State) => state.session)
  const user = useSelector((state: State) => state.user)
  const loading = useSelector((state: State) => state.loading)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { isRTL } = useDirection()
  const hasFederatedAccounts = supportedIDP.length > 1 ? true : false

  useEffect(() => {
    if (client[SUPPORT_HPID] && (!smsSupportedCountryNumbers || !smsSupportedCountryNumbers.length)) {
      dispatch.session.fetchSmsSupportedCountryNumbers()
    }
  }, [client, dispatch.session, smsSupportedCountryNumbers])

  if (loading.effects.session.fetchSessionInfo || loading.effects.session.initSessionWithFlow) {
    return <UISpinner />
  }

  const handleRememberMe = (e) => {
    dispatch.user.update({ rememberMe: e.target.checked })

    e.target.checked
      ? dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT65'))
      : dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT66'))
  }

  const handleForgotUsername = () => {
    dispatch.user.update({ [IS_PRISTINE]: false })
    dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT68'))
    navigate(PAGE_USERNAME_RECOVERY)
  }


  const _isWebAuthBroker = isWebAuthBroker()
  const showIEUpdateNotification = _isWebAuthBroker && displayUpdateAppNotification
  return (
    <>
      <MainLayout adjustHeight={showIEUpdateNotification && '590px'}>
        <DefaultHeader
          preventLineBreak={user.locale === 'ru_RU'}
          title={t('label.sign_in')}
          hasSignUpLink={true}
           isRTL={isRTL}
        />
        {showIEUpdateNotification && <UpdateAppNotification />}
        {client[SUPPORT_HPID] && (
          <>
            <UsernameForm />
            {user.showRememberMe && (
              <S.RememberWrapper hasFederatedAccounts={hasFederatedAccounts}>
                <Checkbox
                  id="remember-me"
                  name="remember-me"
                  data-testid="remember-me"
                  label={user.usernameType === USERNAME_TYPES.USERNAME_EMAIL ? t('label.remember_me_email') : t('label.remember_me_mobile')}
                  checked={user.rememberMe}
                  onChange={handleRememberMe}
                  onKeyDown={handleCheckboxOnKeyDown}
                />
              </S.RememberWrapper>
            )}
          </>
        )}
        <S.ForgetUsername isRTL={isRTL}>
          {!client?.[SUPPORT_HPID] ? null : (
            <S.FooterWrapper>
              <Button id="forgot-cred" appearance="ghost" onClick={handleForgotUsername} expanded>
                {t('link.forgot_username')}
              </Button>
            </S.FooterWrapper>
          )}
        </S.ForgetUsername>
        {user[PARAM_ALLOW_RETURN] && (
          <S.LinkWrapper>
            <UILink
              id="back-link"
              onClick={() => {
                dispatch.udl.trackEvent(udlEvents.getEventByID('EVENT77'))
                dispatch.user.redirectToAPP()
              }}
            >
              {t('link.not_now')}
            </UILink>
          </S.LinkWrapper>
        )}
        <SocialWrapper/>
      </MainLayout>
    </>
  )
}

export default Username
