import styled from '@emotion/styled'

export const OOBESignUpForm = styled.form`
  display: flex;
  flex-direction: column;
  height: 534px;
  @media all and (max-height: 754px) and (max-width: 661px) {
    height: 420px;
  }
`

export const FormContainer = styled.div`
  flex: 1;
  > * {
    margin-top: 20px;
  }
  > div:nth-of-type(1) {
    margin-top: 0px;
  }
`

export const GreetingText = styled.p`
  margin: 0;
`

export const DescriptionText = styled.p`
  margin: 20px 0;
`

export const FormFooter = styled.div<{ isRTL?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  > * {
    margin-top: 20px;
  }
  ${(props) =>
    props.isRTL &&
    `
    button {
      margin-top: 20px;
    }
  `}
  > div:nth-of-type(1) {
    margin-top: 10px;
  }
`
export const CheckBoxConsentWorkaround = styled.div`
  label > span.label.vn-checkbox__span--text {
    word-break: normal !important;
  }
`
